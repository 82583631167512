// fetch elevation from google maps api for the notam pages

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.showElevationFromGoogleMaps()
    }

    showElevationFromGoogleMaps() {
        let elevator = new google.maps.ElevationService();
        let location = new google.maps.LatLng(this.element.dataset.lat, this.element.dataset.lon);
        let height = Math.round(Number(this.element.dataset.height) * 3.281)
        elevator
            .getElevationForLocations({locations: [location]})
            .then(({results}) => {
                if (results[0]) {
                    let elev = Math.round(results[0].elevation * 3.281)
                    let res = Math.round(results[0].resolution * 3.281)
                    this.element.textContent
                        = `Elevation reported at centre of ${elev.toLocaleString()} ft with a resolution of ${res.toLocaleString()} ft. Calculated max height AMSl would be ${(elev + height).toLocaleString()} ft.`
                } else {
                    console.log("elevation error in response")
                }
            })
            .catch((e) => {
                console.log("elevation error: " + e)
            })
    }
}
